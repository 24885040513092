var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";
import { Button, Input, Spinner } from "components/atoms";
var schema = yup.object({
    email: yup.string().email().required(),
    password: yup.string().required(),
});
var LoginForm = function (_a) {
    var _b, _c;
    var onSubmit = _a.onSubmit, onForgotPw = _a.onForgotPw, isLoading = _a.isLoading;
    var _d = useForm({
        defaultValues: { email: "", password: "" },
        resolver: yupResolver(schema),
    }), reset = _d.reset, register = _d.register, submit = _d.handleSubmit, _e = _d.formState, errors = _e.errors, isSubmitting = _e.isSubmitting, isSubmitted = _e.isSubmitted, dirtyFields = _e.dirtyFields;
    var handleSubmit = submit(function (data) {
        onSubmit(data);
        // reset form so that it is no longer "dirty" after a failed submit
        reset(__assign({}, data), { keepValues: true });
    });
    var canSubmit = !isSubmitting &&
        ((dirtyFields.email && dirtyFields.password) || // if all fields are dirty
            (isSubmitted && (dirtyFields.email || dirtyFields.password))); // if submitted and now a field is changed
    return (_jsxs("div", __assign({ className: "relative" }, { children: [isLoading && _jsx(Spinner, {}, void 0), _jsxs("form", __assign({ className: classNames("flex items-center flex-col", {
                    "opacity-25": isLoading,
                }), onSubmit: handleSubmit, noValidate: true }, { children: [_jsx("div", __assign({ className: "font-bold h-5 text-h5 text-black" }, { children: _jsx(FormattedMessage, { id: "common.form.login.title" }, void 0) }), void 0), _jsxs("div", __assign({ className: "w-full" }, { children: [_jsx("div", __assign({ className: "my-2" }, { children: _jsx(Input, __assign({ disabled: isLoading, isRequired: true, type: "text", error: (_b = errors.email) === null || _b === void 0 ? void 0 : _b.message, label: _jsx(FormattedMessage, { id: "common.form.login.field.email.label" }, void 0) }, register("email")), void 0) }), void 0), _jsx("div", __assign({ className: "my-2" }, { children: _jsx(Input, __assign({ disabled: isLoading, isRequired: true, type: "password", error: (_c = errors.password) === null || _c === void 0 ? void 0 : _c.message, label: _jsx(FormattedMessage, { id: "common.form.login.field.password.label" }, void 0) }, register("password")), void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "my-3 w-full" }, { children: _jsx(Button, __assign({ fullwidth: true, type: "submit", disabled: !canSubmit }, { children: _jsx(FormattedMessage, { id: "common.form.login.submitBtn.label" }, void 0) }), void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "flex items-center flex-col" }, { children: _jsx("button", __assign({ onClick: onForgotPw, className: "text-primary no-underline text-small leading-small font-bold" }, { children: _jsx(FormattedMessage, { id: "common.form.login.forgotPassword.link" }, void 0) }), void 0) }), void 0)] }), void 0));
};
export default LoginForm;

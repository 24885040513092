var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useParams } from "react-router-dom";
import NotFound from "_ClientApp/pages/public/NotFound";
import users from "../dummyData";
var UserFavAnimals = function () {
    var id = useParams().id;
    var user = users.find(function (user) { return "".concat(user.id) === id; });
    if (!user) {
        return _jsx(NotFound, {}, void 0);
    }
    return (_jsxs("div", { children: [_jsxs("p", __assign({ className: "text-lg text-black font-semibold" }, { children: ["".concat(user.first_name, " ").concat(user.last_name), "s favorite animals:"] }), void 0), user.fav_animals.map(function (animal) { return (_jsx("div", __assign({ className: "m-1 py-8 px-8 max-w-sm mx-auto bg-white rounded-xl shadow-md space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6" }, { children: _jsx("div", __assign({ className: "text-center space-y-2 sm:text-left" }, { children: _jsxs("div", __assign({ className: "space-y-0.5" }, { children: [_jsx("p", __assign({ className: "text-lg text-black font-semibold" }, { children: animal.common_name }), void 0), _jsx("p", __assign({ className: "text-gray-500 font-medium" }, { children: animal.scientific_name }), void 0)] }), void 0) }), void 0) }), animal.scientific_name)); }), _jsx(Link, __assign({ className: "text-purple-600 hover:underline", to: "/users/".concat(user.id) }, { children: "Go Back" }), void 0)] }, void 0));
};
export default UserFavAnimals;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import NotFoundView from "components/templates/NotFoundView";
import { useAuth } from "contexts/AuthContext";
import Account from "_ClientApp/pages/private/Account";
import MyCompany from "_ClientApp/pages/private/MyCompany";
import Users, { UserFavAnimals, UsersList, UsersView } from "_ClientApp/pages/private/Users";
import ForgotPassword from "_ClientApp/pages/public/ForgotPassword";
import Login from "_ClientApp/pages/public/Login";
import Notfound from "_ClientApp/pages/public/NotFound";
import Profile from "../pages/private/Profile";
import PrivateLayout from "./layouts/Private";
import PublicLayout from "./layouts/Public";
var AppRouter = function () {
    var isAuthenticated = useAuth().isAuthenticated;
    return (_jsx(Router, { children: _jsxs(Routes, { children: [_jsxs(Route, __assign({ path: "/", element: isAuthenticated ? _jsx(PrivateLayout, {}, void 0) : _jsx(Navigate, { to: "/login" }, void 0) }, { children: [_jsx(Route, { index: true, element: _jsx(Navigate, { to: "/users" }, void 0) }, void 0), _jsxs(Route, __assign({ path: "users", element: _jsx(Users, {}, void 0) }, { children: [_jsx(Route, { index: true, element: _jsx(UsersList, {}, void 0) }, void 0), _jsx(Route, { path: "/users/:id", element: _jsx(UsersView, {}, void 0) }, void 0), _jsx(Route, { path: "/users/:id/fav_animals", element: _jsx(UserFavAnimals, {}, void 0) }, void 0)] }), void 0), _jsx(Route, { path: "my-page", element: _jsx(Profile, {}, void 0) }, void 0), _jsx(Route, { path: "account", element: _jsx(Account, {}, void 0) }, void 0), _jsx(Route, { path: "my-company", element: _jsx(MyCompany, {}, void 0) }, void 0), isAuthenticated && _jsx(Route, { path: "*", element: _jsx(NotFoundView, {}, void 0) }, void 0)] }), void 0), _jsxs(Route, __assign({ path: "/login", element: _jsx(PublicLayout, {}, void 0) }, { children: [_jsx(Route, { index: true, element: _jsx(Login, {}, void 0) }, void 0), _jsx(Route, { path: "reset-password", element: _jsx(ForgotPassword, {}, void 0) }, void 0)] }), void 0), _jsx(Route, { path: "/*", element: _jsx(Notfound, {}, void 0) }, void 0)] }, void 0) }, void 0));
};
export default AppRouter;

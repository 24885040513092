var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Button } from "components/atoms";
import ForgotPWForm from "components/templates/forms/ForgotPWForm";
import { useAlert } from "contexts/AlertContext";
import { useRequestPasswordResetMutation } from "generated/graphql";
var ForgotPWPage = function () {
    var _a = __read(useState(false), 2), emailSent = _a[0], setEmailSent = _a[1];
    var intl = useIntl();
    var onSuccessAlert = useAlert().onSuccessAlert;
    var navigate = useNavigate();
    var _b = __read(useRequestPasswordResetMutation({
        onCompleted: function () {
            setEmailSent(true);
            onSuccessAlert(intl.formatMessage({ id: "common.api.success.emailSent" }));
        },
    }), 2), requestPWResetMutation = _b[0], loading = _b[1].loading;
    var handleSubmit = function (_a) {
        var email = _a.email;
        requestPWResetMutation({ variables: { email: email } });
    };
    return (_jsxs(_Fragment, { children: [_jsx(ForgotPWForm, { onSubmit: handleSubmit, isLoading: loading, emailSent: emailSent }, void 0), _jsx("div", __assign({ className: "my-2 flex items-center flex-col" }, { children: _jsx(Button, __assign({ onClick: function () { return navigate("/login"); }, inverted: true, fullwidth: true }, { children: _jsx(FormattedMessage, { id: "common.form.forgotPW.returnBtn.label" }, void 0) }), void 0) }), void 0)] }, void 0));
};
export default ForgotPWPage;

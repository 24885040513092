var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ApiClient from "api/ApiClient";
import AlertProvider from "contexts/AlertContext";
import AuthProvider from "contexts/AuthContext";
import IntlProvider from "contexts/IntlContext";
import ClientEN from "./locale/en.json";
import ClientJA from "./locale/ja.json";
import Router from "./router";
var App = function () { return (_jsx(IntlProvider, __assign({ messages: { en: ClientEN, ja: ClientJA } }, { children: _jsx(AuthProvider, { children: _jsx(AlertProvider, { children: _jsx(ApiClient, { children: _jsx(Router, {}, void 0) }, void 0) }, void 0) }, void 0) }), void 0)); };
export default App;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import logoSrc from "assets/images/logoRed.svg";
import { Icon } from "components/atoms";
import { useAuth } from "contexts/AuthContext";
import BigLink from "./bigLink";
import LocaleSelect from "./localeSelect";
import SmallLink from "./smallLink";
var Sidebar = function (_a) {
    var links = _a.links;
    var intl = useIntl();
    var _b = useAuth(), clearAuth = _b.clearAuth, highestRole = _b.highestRole;
    return (_jsxs("div", __assign({ className: "flex flex-col w-sidebar overflow-auto flex-shrink-0 py-3" }, { children: [_jsx("img", { src: logoSrc, className: "px-3 mt-6 mb-3" }, void 0), _jsx(BigLink, { text: intl.formatMessage({
                    id: "common.privateLayout.sidebar.profile.navLink",
                }), to: "my-page", iconName: "UserCircle" }, void 0), _jsx("div", { className: "h-px bg-black10 m-3" }, void 0), _jsx("div", __assign({ className: "flex-1" }, { children: links
                    .filter(function (link) { return !link.permittedRoles || link.permittedRoles.includes(highestRole); })
                    .map(function (link) {
                    return link.iconName ? (_jsx(BigLink, { to: link.to, iconName: link.iconName, text: link.text }, link.text)) : (_jsx(SmallLink, { to: link.to, text: link.text }, link.text));
                }) }), void 0), _jsx("div", { className: "h-px bg-black10 my-4 mx-3" }, void 0), _jsx("div", __assign({ className: "cursor-pointer" }, { children: _jsxs("button", __assign({ onClick: function () {
                        clearAuth();
                        window.location.href = "/login";
                    }, className: "px-3 flex items-center border-l-2 text-black90 border-transparent" }, { children: [_jsx("div", __assign({ className: "text-size-h4" }, { children: _jsx(Icon, { className: "flex", name: "LogOut" }, void 0) }), void 0), _jsx("div", __assign({ className: "ml-2 text-size-small font-bold" }, { children: intl.formatMessage({
                                id: "common.privateLayout.sidebar.logout.title",
                            }) }), void 0)] }), void 0) }), void 0), _jsx(LocaleSelect, {}, void 0), _jsxs("div", __assign({ className: "px-3" }, { children: [_jsx(Link, __assign({ to: "/privacy", className: "mb-2 flex items-center no-underline text-black50 text-size-caption font-bold" }, { children: intl.formatMessage({
                            id: "common.privateLayout.sidebar.privacyPolicy.navLink",
                        }) }), void 0), _jsx(Link, __assign({ to: "/terms", className: "mb-2 flex items-center no-underline text-black50 text-size-caption font-bold" }, { children: intl.formatMessage({ id: "common.privateLayout.sidebar.terms.navLink" }) }), void 0), _jsx("div", __assign({ className: "text-size-caption text-black50" }, { children: intl.formatMessage({ id: "common.copyright.text" }) }), void 0)] }), void 0)] }), void 0));
};
export default Sidebar;

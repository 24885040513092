var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useNavigate, useParams } from "react-router-dom";
import NotFound from "_ClientApp/pages/public/NotFound";
import users from "../dummyData";
var UserView = function () {
    var _a;
    var navigate = useNavigate();
    var id = useParams().id;
    var user = users.find(function (user) { return "".concat(user.id) === id; });
    var hasFavAnimals = !!user && ((_a = user === null || user === void 0 ? void 0 : user.fav_animals) === null || _a === void 0 ? void 0 : _a.length) > 0;
    if (!user) {
        return _jsx(NotFound, {}, void 0);
    }
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: "py-8 px-8 max-w-sm mx-auto bg-white rounded-xl shadow-md space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6" }, { children: [_jsx("img", { className: "block mx-auto h-24 w-24 rounded-full sm:mx-0 sm:flex-shrink-0", src: user.img_url, alt: "avatar", loading: "lazy" }, void 0), _jsxs("div", __assign({ className: "text-center space-y-2 sm:text-left" }, { children: [_jsxs("div", __assign({ className: "space-y-0.5" }, { children: [_jsx("p", __assign({ className: "text-lg text-black font-semibold" }, { children: "".concat(user.first_name, " ").concat(user.last_name) }), void 0), _jsx("p", __assign({ className: "text-gray-500 font-medium" }, { children: user.email }), void 0)] }), void 0), _jsx("button", __assign({ className: "\n              px-4 \n              py-1\n              text-sm\n              font-semibold\n              rounded-full\n              border\n              ".concat(hasFavAnimals
                                    ? "border-purple-200 text-purple-600 hover:text-white hover:bg-purple-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2"
                                    : "border-gray-200 text-gray-300 cursor-not-allowed bg-gray-100", "\n            "), onClick: function () { return navigate("../".concat(user.id, "/fav_animals")); }, disabled: user.fav_animals.length === 0 }, { children: "Favorite animals" }), void 0)] }), void 0)] }), void 0), _jsx(Link, __assign({ className: "text-purple-600 hover:underline", to: "/users" }, { children: "Go Back" }), void 0)] }, void 0));
};
export default UserView;

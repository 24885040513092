var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import users from "../dummyData";
var Users = function () {
    return (_jsxs("div", { children: [_jsx("p", { children: "Please choose a user:" }, void 0), _jsx("nav", __assign({ className: "m-4" }, { children: _jsx("ul", __assign({ className: "list-disc" }, { children: users.map(function (user) { return (_jsx("li", { children: _jsx(Link, __assign({ className: "text-purple-600 hover:underline", to: "".concat(user.id) }, { children: "".concat(user.first_name, " ").concat(user.last_name) }), void 0) }, user.id)); }) }), void 0) }), void 0)] }, void 0));
};
export default Users;

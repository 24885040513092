var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { configureScope, setUser as setSentryUser } from "@sentry/react";
import { createContext, useContext, useEffect, useMemo, useState, } from "react";
import { RoleName } from "generated/graphql";
var authContext = createContext({
    userEmail: "",
    isAuthenticated: false,
    roleSet: new Set(),
    highestRole: RoleName.Entor,
    onAuth: function (props) { },
    clearAuth: function () { },
    onEmailSet: function (email) { },
    token: "",
});
var ProvideAuth = function (_a) {
    var children = _a.children;
    var _b = __read(useState({
        userEmail: localStorage.getItem("userEmail") || "",
        isAuthenticated: !!localStorage.getItem("isAuthenticated"),
        token: localStorage.getItem("token") || "",
        roles: localStorage.getItem("roles") !== null ? JSON.parse(localStorage.getItem("roles")) : [],
    }), 2), _c = _b[0], userEmail = _c.userEmail, isAuthenticated = _c.isAuthenticated, token = _c.token, roles = _c.roles, setAuthenticated = _b[1];
    // set sentry email for error logging
    useEffect(function () {
        if (isAuthenticated) {
            setSentryUser({ email: userEmail });
        }
        else {
            configureScope(function (scope) { return scope.setUser(null); });
        }
    }, [userEmail, isAuthenticated]);
    var handleAuthenticated = function (_a) {
        var token = _a.token, roles = _a.roles;
        setAuthenticated({ userEmail: userEmail, isAuthenticated: true, token: token, roles: roles });
        localStorage.setItem("roles", JSON.stringify(roles));
        localStorage.setItem("token", token);
        localStorage.setItem("isAuthenticated", "true");
    };
    var handleClear = function () {
        setAuthenticated({ userEmail: "", isAuthenticated: false, token: "", roles: [] });
        localStorage.clear();
    };
    var handleSetEmail = function (email) {
        setAuthenticated({ userEmail: email, isAuthenticated: isAuthenticated, token: token, roles: roles });
        localStorage.setItem("userEmail", email);
    };
    var roleSet = useMemo(function () { return new Set(roles); }, [roles]);
    return (_jsx(authContext.Provider, __assign({ value: {
            isAuthenticated: isAuthenticated,
            token: token,
            userEmail: userEmail,
            roleSet: roleSet,
            highestRole: roles[0],
            onAuth: handleAuthenticated,
            onEmailSet: handleSetEmail,
            clearAuth: handleClear,
        } }, { children: children }), void 0));
};
export var useAuth = function () {
    return useContext(authContext);
};
export default ProvideAuth;

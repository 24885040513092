var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage, useIntl } from "react-intl";
import { Spinner } from "components/atoms";
import Header from "components/molecules/Header";
import ClientForm from "components/templates/forms/ClientForm";
import { useAlert } from "contexts/AlertContext";
import { ClientStatus, useMyCompanyQuery, useUpdateMyCompanyMutation } from "generated/graphql";
var MyCompany = function () {
    var _a, _b;
    var alert = useAlert();
    var Intl = useIntl();
    var _c = useMyCompanyQuery({
        // TODO: Update the backend to return the new company data and set fetchPolicy to cacheFirst
        fetchPolicy: "cache-and-network",
    }), data = _c.data, loading = _c.loading, error = _c.error;
    var _d = __read(useUpdateMyCompanyMutation(), 1), updateMyCompanyMutation = _d[0];
    var initialValues = (data === null || data === void 0 ? void 0 : data.currentCompany) && {
        company: {
            address1: data.currentCompany.address1,
            address2: data.currentCompany.address2,
            backgroundImageUrl: data.currentCompany.backgroundImageUrl || "",
            employeesNum: data.currentCompany.employeesNum,
            foundationDate: typeof data.currentCompany.foundationDate === "string"
                ? data.currentCompany.foundationDate.substring(0, 10)
                : null,
            logoImageUrl: data.currentCompany.logoImageUrl || "",
            name: data.currentCompany.name,
            newGraduateNum: data.currentCompany.newGraduateNum,
            phoneNumber: data.currentCompany.phoneNumber,
            postalCode: data.currentCompany.postalCode,
            presidentName: data.currentCompany.presidentName || "",
            url: data.currentCompany.url || "",
            prefectureId: ((_a = data.currentCompany.prefecture) === null || _a === void 0 ? void 0 : _a.id) || 0,
            industryId: ((_b = data.currentCompany.industry) === null || _b === void 0 ? void 0 : _b.id) || 0,
        },
        contactEmail: "",
        status: ClientStatus.Hidden,
        contents: undefined, // TODO: implement contents
    };
    var handleSubmit = function (data) {
        updateMyCompanyMutation({
            variables: {
                input: __assign(__assign({}, data), { contents: [] }),
            },
            onCompleted: function () {
                alert.onSuccessAlert(Intl.formatMessage({ id: "common.api.success.mutation" }));
            },
        });
    };
    return loading ? (_jsx(Spinner, {}, void 0)) : error ? (_jsx("p", { children: "Error" }, void 0)) : (_jsxs("div", __assign({ className: "pt-4 pl-3 w-full h-full flex flex-col" }, { children: [_jsx(Header, { title: _jsx(FormattedMessage, { id: "common.form.client.update.title" }, void 0) }, void 0), _jsx(ClientForm, { onSubmit: handleSubmit, isLoading: false, restricted: true, defaultValues: initialValues }, void 0)] }), void 0));
};
export default MyCompany;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import SingleSelect from "components/molecules/FormSelects/SingleSelect";
import { useIndustriesQuery } from "generated/graphql";
var formatForSelect = function (industry) { return ({
    label: industry.name,
    id: String(industry.id),
    value: industry.id,
}); };
var IndustrySelect = function (props) {
    var data = useIndustriesQuery().data;
    var options = useMemo(function () { return data === null || data === void 0 ? void 0 : data.industries.map(formatForSelect); }, [data]);
    return (_jsx(SingleSelect, __assign({}, props, { options: options, name: "status", label: _jsx(FormattedMessage, { id: "common.form.field.singleSelect.industry.label" }, void 0) }), void 0));
};
export default IndustrySelect;

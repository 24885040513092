var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";
import { Button, Input, Spinner } from "components/atoms";
var schema = yup.object({
    email: yup.string().email().required(),
});
var ForgotPWForm = function (_a) {
    var _b;
    var onSubmit = _a.onSubmit, isLoading = _a.isLoading, emailSent = _a.emailSent;
    var _c = useForm({
        defaultValues: { email: "" },
        resolver: yupResolver(schema),
    }), reset = _c.reset, register = _c.register, submit = _c.handleSubmit, _d = _c.formState, errors = _d.errors, isSubmitting = _d.isSubmitting, isValid = _d.isValid, isSubmitted = _d.isSubmitted, dirtyFields = _d.dirtyFields;
    var handleSubmit = submit(function (data) {
        onSubmit(data);
        // reset form so that it is no longer "dirty" after a failed submit
        reset(__assign({}, data), { keepValues: true });
    });
    var canSubmit = !isSubmitting &&
        (dirtyFields.email || // if email field is dirty
            (isSubmitted && dirtyFields.email)); // if submitted and now a field is changed
    return (_jsxs("div", __assign({ className: "relative" }, { children: [isLoading && _jsx(Spinner, {}, void 0), _jsxs("form", __assign({ className: classNames("flex items-center flex-col", {
                    "opacity-25": isLoading,
                }), onSubmit: handleSubmit, noValidate: true }, { children: [_jsx("div", __assign({ className: "font-bold h-5 text-h5 text-black" }, { children: emailSent ? (_jsx(FormattedMessage, { id: "common.form.forgotPW.emailsent.title" }, void 0)) : (_jsx(FormattedMessage, { id: "common.form.forgotPW.title" }, void 0)) }), void 0), _jsx("p", __assign({ className: "text-small text-center text-black90" }, { children: emailSent ? (_jsx(FormattedMessage, { id: "common.form.forgotPW.emailsent.subtitle" }, void 0)) : (_jsx(FormattedMessage, { id: "common.form.forgotPW.subtitle" }, void 0)) }), void 0), !emailSent && (_jsxs("div", __assign({ className: "w-full" }, { children: [_jsx("div", __assign({ className: "my-3" }, { children: _jsx(Input, __assign({ disabled: isLoading || emailSent, isRequired: true, type: "text", error: (_b = errors.email) === null || _b === void 0 ? void 0 : _b.message, label: _jsx(FormattedMessage, { id: "common.form.login.field.email.label" }, void 0) }, register("email")), void 0) }), void 0), _jsx(Button, __assign({ fullwidth: true, type: "submit", disabled: !canSubmit }, { children: _jsx(FormattedMessage, { id: "common.form.forgotPW.submitBtn.label" }, void 0) }), void 0)] }), void 0))] }), void 0)] }), void 0));
};
export default ForgotPWForm;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import { Link, Outlet } from "react-router-dom";
import loginBgImg from "assets/images/clientHero.png";
import logoImg from "assets/images/logoRed.svg";
import HeroView from "components/templates/HeroView";
var PublicLayout = function () { return (_jsx(HeroView, __assign({ bgImg: loginBgImg, logoImg: logoImg, privacyLink: _jsx(Link, __assign({ to: "../privacy" }, { children: _jsx(FormattedMessage, { id: "client.page.login.privacy.link" }, void 0) }), void 0), termsLink: _jsx(Link, __assign({ to: "../terms" }, { children: _jsx(FormattedMessage, { id: "client.page.login.terms.link" }, void 0) }), void 0) }, { children: _jsx(Outlet, {}, void 0) }), void 0)); };
export default PublicLayout;

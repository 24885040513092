var users = [
    {
        id: 1,
        first_name: "Bobbye",
        last_name: "Strangeways",
        email: "bstrangeways0@archive.org",
        img_url: "https://robohash.org/culpaquibusdamsint.png?size=50x50&set=set1",
        fav_animals: [
            {
                scientific_name: "Crotalus triseriatus",
                common_name: "Rattlesnake, dusky",
            },
            {
                scientific_name: "Tapirus terrestris",
                common_name: "Tapir, brazilian",
            },
            {
                scientific_name: "Bugeranus caruncalatus",
                common_name: "Crane, wattled",
            },
            {
                scientific_name: "Geococcyx californianus",
                common_name: "Roadrunner, greater",
            },
            {
                scientific_name: "Meleagris gallopavo",
                common_name: "Common turkey",
            },
            {
                scientific_name: "Heloderma horridum",
                common_name: "Lizard, mexican beaded",
            },
            {
                scientific_name: "Leprocaulinus vipera",
                common_name: "Stick insect",
            },
            {
                scientific_name: "Terrapene carolina",
                common_name: "Turtle, eastern box",
            },
            {
                scientific_name: "Podargus strigoides",
                common_name: "Frogmouth, tawny",
            },
            {
                scientific_name: "Vulpes chama",
                common_name: "Cape fox",
            },
        ],
    },
    {
        id: 2,
        first_name: "Alex",
        last_name: "Benfield",
        email: "abenfield1@skype.com",
        img_url: "https://robohash.org/voluptasiustooptio.png?size=50x50&set=set1",
        fav_animals: [
            {
                scientific_name: "Ara ararauna",
                common_name: "Blue and gold macaw",
            },
            {
                scientific_name: "Dolichitus patagonum",
                common_name: "Mara",
            },
            {
                scientific_name: "Phoeniconaias minor",
                common_name: "Flamingo, lesser",
            },
            {
                scientific_name: "Paradoxurus hermaphroditus",
                common_name: "Common palm civet",
            },
        ],
    },
    {
        id: 3,
        first_name: "Bettye",
        last_name: "Camelia",
        email: "bcamelia2@1688.com",
        img_url: "https://robohash.org/saepeanimiaut.png?size=50x50&set=set1",
        fav_animals: [
            {
                scientific_name: "Crotalus cerastes",
                common_name: "Rattlesnake, horned",
            },
            {
                scientific_name: "Odocoileus hemionus",
                common_name: "Black-tailed deer",
            },
            {
                scientific_name: "Loxodonta africana",
                common_name: "Elephant, african",
            },
            {
                scientific_name: "Vulpes cinereoargenteus",
                common_name: "Grey fox",
            },
            {
                scientific_name: "unavailable",
                common_name: "Python (unidentified)",
            },
            {
                scientific_name: "Morelia spilotes variegata",
                common_name: "Carpet snake",
            },
            {
                scientific_name: "Gyps fulvus",
                common_name: "Vulture, griffon",
            },
            {
                scientific_name: "Varanus salvator",
                common_name: "Water monitor",
            },
        ],
    },
    {
        id: 4,
        first_name: "Jessamyn",
        last_name: "Munnings",
        email: "jmunnings3@360.cn",
        img_url: "https://robohash.org/quidoloribuset.png?size=50x50&set=set1",
        fav_animals: [
            {
                scientific_name: "Phoenicopterus ruber",
                common_name: "Roseat flamingo",
            },
            {
                scientific_name: "unavailable",
                common_name: "Adouri (unidentified)",
            },
            {
                scientific_name: "Phalaropus fulicarius",
                common_name: "Red phalarope",
            },
        ],
    },
    {
        id: 5,
        first_name: "Maximilianus",
        last_name: "De Castri",
        email: "mdecastri4@diigo.com",
        img_url: "https://robohash.org/sapientevelut.png?size=50x50&set=set1",
        fav_animals: [],
    },
];
export default users;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { Spinner } from "components/atoms";
import BasicInfoForm from "components/templates/forms/BasicUserInfoForm";
import { useAlert } from "contexts/AlertContext";
import { CurrentClientDocument, UserGender, useCurrentClientQuery, useUpdateCurrentClientUserMutation, } from "generated/graphql";
var Profile = function () {
    var alert = useAlert();
    var intl = useIntl();
    var _a = useCurrentClientQuery({
        fetchPolicy: "cache-first",
    }), data = _a.data, loading = _a.loading, error = _a.error;
    var initialValues = data
        ? {
            firstName: data === null || data === void 0 ? void 0 : data.currentClientUser.firstName,
            lastName: data === null || data === void 0 ? void 0 : data.currentClientUser.lastName,
            firstNameKana: data === null || data === void 0 ? void 0 : data.currentClientUser.firstNameKana,
            lastNameKana: data === null || data === void 0 ? void 0 : data.currentClientUser.lastNameKana,
            phoneNumber: data === null || data === void 0 ? void 0 : data.currentClientUser.phoneNumber,
            gender: data === null || data === void 0 ? void 0 : data.currentClientUser.gender,
        }
        : undefined;
    var _b = __read(useUpdateCurrentClientUserMutation({
        onCompleted: function (newData) {
            //After updateAdminMutation has been completed, we simply update the cache of CurrentAdmin query
            //so the profile data fetched from cache and displayed is always up-to-date.
            var oldData = client.cache.readQuery({
                query: CurrentClientDocument,
            });
            client.cache.writeQuery({
                query: CurrentClientDocument,
                data: {
                    currentAdmin: __assign({ email: oldData.currentClientUser.email }, newData.updateCurrentClientUser),
                },
            });
        },
    }), 2), updateCurrentClientUserMutation = _b[0], client = _b[1].client;
    return error ? (_jsx("p", { children: "Error" }, void 0)) : (_jsxs("div", __assign({ className: "w-full h-auto mt-4 flex flex-col" }, { children: [_jsx("h4", __assign({ className: "px-6 py-4 bg-gradient" }, { children: _jsx(FormattedMessage, { id: "common.mypage.profile.header.label" }, void 0) }), void 0), _jsx("div", __assign({ className: "bg-white flex0 h-full" }, { children: loading ? (_jsx(Spinner, {}, void 0)) : (_jsx(BasicInfoForm, { onSubmit: function (data) {
                        updateCurrentClientUserMutation({
                            variables: {
                                firstName: data.firstName,
                                lastName: data.lastName,
                                firstNameKana: data.firstNameKana,
                                lastNameKana: data.lastNameKana,
                                gender: data.gender || UserGender.Other,
                                phoneNumber: data.phoneNumber,
                            },
                            onCompleted: function () {
                                alert.onSuccessAlert(intl.formatMessage({ id: "common.api.success.mutation" }));
                            },
                        });
                    }, onChangePWClick: console.log, isLoading: false, initialValues: initialValues }, void 0)) }), void 0)] }), void 0));
};
export default Profile;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var Examples = function () { return (_jsxs("div", { children: [_jsx("p", __assign({ className: "text-2xl" }, { children: "Account" }), void 0), _jsx("div", __assign({ className: "rounded-t-xl overflow-hidden bg-gradient-to-r from-emerald-50 to-teal-100 p-10" }, { children: _jsx("p", __assign({ className: "overflow-ellipsis overflow-hidden max-w-xs px-6 py-4 mx-auto bg-emerald-200 text-emerald-500 font-medium rounded-lg" }, { children: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiisitaquequodpraesentiumexplicaboincidunt? Dolores beatae nam at sed dolorum ratione dolorem nisi velit cum." }), void 0) }), void 0)] }, void 0)); };
export default Examples;
